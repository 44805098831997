import { MouseEvent } from 'react';
import { animateScroll } from 'react-scroll';

export const scrollToElement = (selectorOrElement: string | HTMLElement) => {
  const element = typeof selectorOrElement === 'string' 
    ? document.querySelector(selectorOrElement)
    : selectorOrElement

  if (!element) {
    return;
  }

  const headerHeight = 53 + 10;
  const top = element.getBoundingClientRect().top + window.scrollY - headerHeight;

  animateScroll.scrollTo(top, { smooth: true, duration: 400 });
}

export const scrollOnClick = (event: MouseEvent<HTMLAnchorElement>) => {
  event.preventDefault();
  const selector = event.currentTarget.getAttribute('href')

  if (!selector) {
    return;
  }

  scrollToElement(selector)
}