import React, { useState } from 'react';
import cx from 'classnames';

import { scrollOnClick } from '../../utils/scrollToElement';

import logo from '../../assets/images/logo.png';
import bars from '../../assets/images/three-bar-icon.png';
import qrCode from '../../assets/images/qr-white.png';

import styles from './Header.module.scss';

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = (event: any) => {
    setMenuOpen(false);
    scrollOnClick(event);
  }

  return (
    <header className={styles.header}>
      <div className="container">
        <button className={styles.menuButton} onClick={() => setMenuOpen(!menuOpen)}>
          <img src={bars} alt="Menu Bars" />
        </button>

        <a href="/" className={styles.logo}>
          <img src={logo} alt="Chalkboards Logo" />
        </a>

        <a href="#grCodeSection" className={styles.qrCode} onClick={scrollOnClick} title="Scroll to QR code">
          <img src={qrCode} alt="Qr code" />
        </a>
      </div>

      <nav className={cx(styles.nav, { [styles.active]: menuOpen })}>
        <div className="container">
          <ul>
            <li>
              <a href="#favourites" title="My Favorites" onClick={handleMenuClick}>My Favourites</a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}