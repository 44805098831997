import axios from 'axios';

const urlParams = new URLSearchParams(window.location.search)
const server = urlParams.get('server');
const isDemo = urlParams.get('demo') === 'true';

const baseUrl = server
    ? `http://${server}`
    : `https://${isDemo ? 'simfel.com' : 'communitylive.ws'}`;

    

export const request = axios.create({
    baseURL: baseUrl
});
