import React from 'react';
import cx from 'classnames';

import { useHomeData } from './useHomeData';

import { Footer } from "../../components/Footer"
import { Header } from "../../components/Header"
import { Section, SectionContent, SectionTitle } from "../../components/Section"
import { LoadingElement } from '../../components/LoadingElement';

import keyTagSrc from '../../assets/images/keytag.png'

import styles from './Home.module.scss';
import { Store } from '../../components/Store';

export const Home = () => {
  let { 
    qrCodeURL,
    loading,
    error,
    sitelettes,
    userRegistrationDetails,
  } = useHomeData();

  

  const hasBanners = sitelettes && sitelettes.length > 0;

  return (
    <>
      <Header />
      <main className={styles.mainContent}>
        
        <Section id="favourites">
          <SectionTitle>My Offers</SectionTitle>
          <LoadingElement loading={loading}>
            <SectionContent className={cx('container', styles.sectionContent)}>
              {!loading && !error && hasBanners && sitelettes.map(s => (
                <Store store={s} uid={userRegistrationDetails.uid} />
              ))}

              {!loading && !hasBanners && !error && (
                <p className={styles.noBannersText}>( you do not have any Specials or Offers yet )</p>
              )}

              {error && 'Error... Unable to load your offers'}
            </SectionContent>
          </LoadingElement>
        </Section>

        <Section id="grCodeSection">
          <SectionTitle>Your key tag</SectionTitle>
          <LoadingElement loading={loading}>
            <SectionContent className={cx('container', styles.sectionContent)}>
              {!loading && !error && (
                <>
                  <p>For loyalty, specials, and order history,
                  just check-in with the key-tag.</p>

                  <p>Click on the tag to display scannable QR code.</p>

                  <img src={keyTagSrc} alt="keytag" className={styles.keytag} />

                  {qrCodeURL && <img src={qrCodeURL} alt="QR code" className={styles.qrCode} /> }
                </>
              )}

              {error && 'Error... Unable to load your key tag'}
            </SectionContent>
          </LoadingElement>
        </Section>
      </main>

      <Footer />
    </>
  )
}