import React from 'react';
import cx from 'classnames';

import styles from './Footer.module.scss';

export const Footer = () => {

  return (
    <footer className={styles.footer}>
      <div className={cx('container', styles.top)}>
        <ul>
          <li>
            <a href="https://chalkboardstoday.com/index.php#contact" title="Contact Us">Contact Us</a>
          </li>
          <li>
            <a href="https://chalkboardstoday.com/support" title="Support">Support</a>
          </li>
        </ul>

        <ul>
          <li>
            <a href="https://chalkboardstoday.com/privacypolicy" title="Privacy Policy">Privacy Policy</a>
          </li>
          <li>
            <a href="https://chalkboardstoday.com/termsandconditions" title="Terms & Conditions">Terms & Conditions</a>
          </li>
        </ul>
      </div>

      <div className={styles.bottom}>
        <div className="container">© 2019. All rights reserved. | by Orinoco Inc.</div>
      </div>
    </footer>
  )
}