import React from 'react';

import styles from './Store.module.scss';

type TProps = {
  uid: string,
  store: { 
    siteURL: string,
    bannerImageURL: string,
    discountMap: {
      [key: string]: {
        onClickURL: string,
        imageURL: string,
      }
    }
  } 
}

export const Store: React.VFC<TProps> = (props) => {
  const {
    uid,
    store: {
      siteURL,
      bannerImageURL,
      discountMap,
    }
  } = props;

  const addUuidToUrl = (url: string, uid: string) => {
    return url.includes('?') ? `${url}&uid=${uid}` : `${url}?uid=${uid}`;
  }

  const url = addUuidToUrl(siteURL, uid);
  const discounts = Object.values(discountMap);

  return (
    <div className={styles.store}>
      <a href={url} className={styles.banner} target="_blank" rel="noreferrer">
        <img src={bannerImageURL} alt="Store banner" loading="lazy" />
      </a>

      {discounts.length > 0 && discounts.map(discount => {
        return (
          <a href={discount.onClickURL} className={styles.banner} target="_blank" rel="noreferrer">
            <img src={discount.imageURL} alt="Promotion banner" loading="lazy"  />
          </a>
        )
      })}
    </div>
  )
}