import { request } from './request';

export const retrieveUserAndSASLsByKUID = async ({ uid }: { uid: string }) => {
  const params = new URLSearchParams(window.location.search);

  const response = await request.post('/apptsvc/rest/usersasl/retrieveUserAndSASLsByKUID', {
    adhoc: false, // TODO: repalce it with real data
    iid: params.get('iid'),
    uid, // TODO: repalce it with real data
  })

  console.log({response})
  
  return response.data;
}
