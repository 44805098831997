import { useEffect, useState } from "react"
import { useCookie } from "react-use";

import { retrieveUserAndSASLsByKUID } from "../../api"
import { USER_COOKIE } from "../../constants";

type TApiResponse = {
  qrCodeURL: string,
  sitelettes: [{
    bannerImageURL: string,
    siteURL: string,
    discountMap: {
      [key: string]: {
        onClickURL: string,
        imageURL: string,
      }
    }
  }],
  userRegistrationDetails: {
    uid: string,
  },
}

type TReturn = TApiResponse & {
  loading: boolean
  error: boolean,
};

export const useHomeData = (): TReturn => {
  const [{
    qrCodeURL,
    sitelettes,
    userRegistrationDetails,
  }, setData] = useState<TApiResponse>({} as any)

  const [userCookieUid] = useCookie(USER_COOKIE);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await retrieveUserAndSASLsByKUID({ uid: userCookieUid as string })
        setData(data);
        

        console.log({ data })
      } catch (error) {
        setError(true)
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [])

  return {
    qrCodeURL,
    sitelettes,
    userRegistrationDetails,
    loading,
    error,
  }
}
